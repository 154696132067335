.listItemName {
    margin: 0px;
    padding: 0px;
    font-size: 20px;
    font-weight: 100;
}

.leads-list{
    text-align:left;
    list-style-type:decimal;
}

.leads-list li {
    margin:10px 0px;
    padding:10px;
    border-radius:5px;
}

.leads-border-primary{
    border:solid 3px #29A3EB;
}

.leads-border-secondary{
    border:solid 3px #1D64B6;
}

.leads-mail-icon{
    font-size:35px;
}

.leads-contact-us{
    border:solid 3px grey;
    border-radius:5px;
    text-align:left;
    padding:20px;
    margin-top:13px;
}

.leads-contact-us div{
    flex:1;
    justify-content:center;
}

.leads-steps{
    position:relative;
}

.lead-steps .step-one{
    background-color:#29A3EB;
    color:white;
    border-radius: 5px;
    padding:5px 5px;
    text-align:center;
    top:78px;
    left:-110px;

}

.lead-steps .step-two{
    background-color:#1D64B6;
    color:white;
    border-radius: 5px;
    padding:5px 5px;
    
    text-align:center;
    top:56.8%;
    left:-475px;
}


@media screen and ( min-width: 600px ) {
    .lead-steps .step-one{
        position:absolute;
        transform: rotate(-90deg);
        width:170px;
    
    }
    
    .lead-steps .step-two{
        position:absolute;
        transform: rotate(-90deg);
        width:900px;
    }
}


@media screen and ( max-width: 600px ) {
    .lead-steps .step-one{
        position:static;
        width:100%;
        margin-top:30px;
    
    }
    
    .lead-steps .step-two{
        position:static;
        width:100%;
        margin-top:30px;
    }
}